@import "../../../src/styles/_constants.scss";

@mixin styling($responsive, $responsiveMenu) {

.sms-success {
  text-align: center;
  color: $success;
  font-size: calc(25px * #{$responsive});
  font-weight: bold;
}

  .custom-field {
    width: (170px * #{$responsive}) !important;
  }

  .field-label{
    text-align: center !important;
  }

  .findRecipients {
    // margin-left: 20%;

    // @media (min-width: 1024px) and (max-width: 1407px) {
    //   margin-left: 3%;
    // }
    //
    // @media (min-width: 768px) and (max-width: 1023px) {
    //   margin-left: 4%;
    // }
    //
    // @media (min-width: 426px) and (max-width: 767px) {
    //   display: flex;
    //   justify-content: center;
    //   margin-left: 0;
    // }

    .findRecipient {
      color: $first_color;
      font-family: "OpenSans", sans-serif;
      font-size: calc(14px * #{$responsive});
      font-weight: bold;
      font-style: normal;
      cursor: pointer;
      text-align: center !important;
      margin-bottom: 2%;

      span {
        cursor: pointer;
      }
    }
  }
}

@import "../../../src/styles/_responsive.scss";
